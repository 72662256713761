import axios from "axios";
import { isEmpty } from "lodash";

export const axiosInstance = axios.create({
  url: window?.__RUNTIME_CONFIG__?.VITE_BASE_URL ?? "",
});
export const cancelAxios = axios.CancelToken;

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  async function (error: any) {
    return await Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: any) {
    if (
      error.response.status === 403 &&
      isEmpty(error?.response?.data?.error)
    ) {
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent("PERMISSION_CHANGED", { detail: "REFRESH" })
        );
      }, 1000);
    }
    if (error.response.status === 401) {
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent("PERMISSION_CHANGED", { detail: "SIGN_IN" })
        );
      }, 1000);
    }

    return await Promise.reject(error);
  }
);
