import React, { Fragment, Suspense, useEffect } from "react";
import Header from "./shared/header/header";
import { IRoutes } from "../types/common";
import { SIDEBAR_ROUTES } from "../constants/common";
import { Outlet } from "react-router-dom";
import { Layout, Spin } from "antd";
import Sidebar from "./shared/sidebar/sidebar";
import { ProtectedRouterWrapper } from "../constants/styles/common.styled";
import { Content } from "antd/es/layout/layout";
import { COLORS } from "../constants/global";

interface RoutesContainerType {
  isAuthenticated: boolean;
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  selectedKey: string;
  setSelectedKey: React.Dispatch<React.SetStateAction<string>>;
}

const MasterLayout = ({
  isAuthenticated,
  collapsed,
  setCollapsed,
  selectedKey,
  setSelectedKey,
}: RoutesContainerType): JSX.Element => {
  const navKeySelector = (routeList: IRoutes[]) => {
    routeList.map((route: IRoutes) => {
      if (route.route === location.pathname) {
        setSelectedKey(route.pathname);
      } else if (route.children) {
        navKeySelector(route.children);
      }
    });
  };

  useEffect(() => {
    navKeySelector(SIDEBAR_ROUTES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Fragment>
      <Layout hasSider>
        {isAuthenticated && (
          <Sidebar collapsed={collapsed} selectedKey={selectedKey} />
        )}
        <Layout style={{ marginLeft: collapsed ? 80 : 250 }}>
          {isAuthenticated && (
            <Header
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              isAuthenticated={isAuthenticated}
            />
          )}
          <Content
            style={{
              padding: 20,
              minHeight: "calc(-64px + 100vh)",
              background: COLORS.backgroundColor,
              // borderRadius: borderRadiusLG,
            }}
          >
            <ProtectedRouterWrapper>
              <Suspense
                fallback={<Spin tip="Loading" size="default" fullscreen />}
              >
                <Outlet />
              </Suspense>
            </ProtectedRouterWrapper>
          </Content>
        </Layout>
      </Layout>
    </Fragment>
  );
};

export default MasterLayout;
