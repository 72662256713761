import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Flex, MenuProps, Typography, theme,Layout } from "antd";
import AuthService from "../../../services/authService";
import logo from "../../../assets/logo.png";
import { useEffect, useState } from "react";
import { HeaderWrapper, Logo } from "./header.styled";
import { Link } from "react-router-dom";
import useSmallEarthApi from "../../../hooks/useSmallEarthApi";
import useGetUserProfile from "../../../hooks/shared/getUserProfile";

const { useToken } = theme;
const { Title, Text } = Typography;

interface IHeaderProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  isAuthenticated: boolean;
}

const items: MenuProps["items"] = [
  {
    key: "1",
    label: (
      <div
        className="hell"
        onClick={() => {
          void new AuthService().logout();
        }}
      >
        <LogoutOutlined style={{ marginRight: "8px" }} />
        <Text>Logout</Text>
      </div>
    ),
  },
];
const { Header: AntHeader } = Layout;
const Header = ({ collapsed, setCollapsed, isAuthenticated }: IHeaderProps) => {
  const { token } = useToken();
  const { handleRequest } = useSmallEarthApi();
  const { data } = useGetUserProfile(handleRequest, isAuthenticated);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Update the collapsed state based on the window width
      setIsMobileView(window.innerWidth < 1024);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    setIsMobileView(window.innerWidth < 1024);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AntHeader style={{
      background: "#138140",
      paddingInline: '0 20px ',
      position: 'sticky',
      top: 0,
      zIndex: 99,
      width: '100%',

    }}>
      <Flex justify="space-between" align="center" wrap="nowrap">
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: '16px',
            width: 64,
            height: 64,
            background: '#138140',
            color:'white'
          }}
        />
        <Flex justify="flex-end" align="center" gap={10} >
          {!isMobileView && (
            <Title level={5} style={{ margin: 0 ,color: 'white' }} >
              Welcome, {data?.name || ""}
            </Title>
          )}

          <Dropdown
            menu={{
              items,
              onClick: () => {
                void new AuthService().logout();
              },
            }}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <UserSwitchOutlined
              style={{ cursor: "pointer", fontSize: 18 ,color:'white'}}
            />
          </Dropdown>
        </Flex>
      </Flex>
    </AntHeader>
    // <HeaderWrapper backgroundcolor={token.colorPrimary}>
    //   <div className="container">
    //     <Flex justify="space-between" align="center" wrap="nowrap">
    //       <Flex justify="flex-start" align="center">
    //         <Button
    //           type="default"
    //           size="middle"
    //           rootClassName="collapsable-btn"
    //           onClick={() => setCollapsed(!collapsed)}
    //         >
    //           {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    //         </Button>
    //         <Logo>
    //           <Link to="/">
    //             <img src={logo} alt="Frost & Sullivan Logo" />
    //           </Link>
    //         </Logo>
    //       </Flex>

    //       <Flex justify="flex-end" align="center" className="header-logout">
    //         {!isMobileView && (
    //           <Title level={5} className="header-logout--info">
    //             Welcome, {data?.name || ""}
    //           </Title>
    //         )}

    //         <Dropdown
    //           menu={{
    //             items,
    //             onClick: () => {
    //               void new AuthService().logout();
    //             },
    //           }}
    //           placement="bottomRight"
    //           arrow={{ pointAtCenter: true }}
    //         >
    //           <SettingOutlined
    //             style={{ color: token.colorBgContainer, cursor: "pointer" }}
    //           />
    //         </Dropdown>
    //       </Flex>
    //     </Flex>
    //   </div>
    // </HeaderWrapper>
  );
};

export default Header;
