import { PERMISSION_LIST } from "../constants/permission";
import { groupBy } from "lodash";

// Converts Strings Data with value to groups objects

export const removePrefix = (
  obj: Record<string, boolean | null> | any,
  prefixesToRemove: string[]
) => {
  const newObj: Record<string, boolean | null> = {};

  for (const key in obj) {
    let newKey = key;
    for (const prefix of prefixesToRemove) {
      if (key.startsWith(prefix)) {
        newKey = key.substring(prefix.length + 1); // Remove the prefix and the dot
        break; // Exit the loop once a prefix is removed
      }
    }

    newObj[newKey] = obj[key];
  }

  return newObj;
};

// const getCustomerPolicies = (
//   policies: any,
//   permissionIdentifier: string
// ): string[] =>
//   Object.keys(policies)
//     .map((item) => {
//       return item;
//     })
//     .filter((item) => {
//       return item.startsWith(permissionIdentifier);
//     });

const getCustomerPolicies = (
  policies: Record<string, boolean | null>
): string[] => {
  const grantedPolicies = removePrefix(policies, [
    /* "AbpIdentity",  */ "SmallEarth",
  ]);
  return Object.keys(grantedPolicies)
    .map((item) => {
      return item;
    })
    .filter((item) => {
      const permission = PERMISSION_LIST.map((item) => item.id);
      return permission.includes(item.split(".")[0]);
    });
};

export const restructureNonFieldPermissions = (data: string[]) => {
  return data?.map((item: string) => {
    const permissionParts = item.split(".");
    return {
      groupKey: permissionParts[0],
      key: item === permissionParts[0] ? `${item}.Default` : item,
      text: permissionParts.length > 1 ? permissionParts[1] : "View",
    };
  });
};

export const getFilteredPermissionData = (
  policies?: Record<string, boolean | null> | null
) => {
  const customerPolicies = getCustomerPolicies(policies ?? {});

  const nonFieldLevelPermissionsData =
    restructureNonFieldPermissions(customerPolicies);

  const nonFieldLevelPermissionsGroups = groupBy(
    nonFieldLevelPermissionsData,
    (item: { groupKey: string; key: string; text: string }) => item.groupKey
  );

  return nonFieldLevelPermissionsGroups;
};
