import styled from "styled-components";
import { COLORS } from "../../../constants/global";

interface HeaderWrapperType {
  backgroundcolor: string;
}

export const HeaderWrapper = styled.header<HeaderWrapperType>`
  background-color: ${(props) => props.backgroundcolor};
  padding: 20px 0;
  @media (max-width: 768px) {
    padding: 10px 0;
  }
  .collapsable-btn {
    background: transparent;
    border: none;
    color: ${COLORS.White};
    padding-top: 10px;

    &:hover {
      color: ${COLORS.LightBlue} !important;
    }
  }

  img {
    display: flex;
    align-items: center;
  }

  h5 {
    margin-bottom: 0;
    color: ${COLORS.White};
    font-weight: 500;
    margin-top: 0 !important;
  }

  .header-logout {
    height: 100%;
    // margin-right: 10px;

    &--button {
      cursor: pointer;

      &:hover {
        color: ${COLORS.LightBlue};
      }
    }

    &--info {
      margin-right: 20px;
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 10px;
  width: 90px;
  @media (max-width: 768px) {
    padding-left: 10px;
  }
  & img {
    max-width: 100%;
    height: auto;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ActionList = styled.ul`
  list-style: none;
  display: flex;

  & li {
    margin-left: 10px;
  }
`;
