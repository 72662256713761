import { QueryClient } from "react-query";

export const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 5,
      staleTime: Infinity,
      retry: false,
      retryDelay: 300,
      refetchOnWindowFocus: false,
    },
  },
});

export const THEME_CONSTANTS = {
  fontSize: 16,
  sizeUnit: 4,
  sizeStep: 4,
  menu: {
    collapsedIconSize: 16,
    collapsedWidth: 80,
    dropdownWidth: 160,
    iconSize: 14,
    itemHeight: 40,
    itemPaddingInline: 16,
    itemMarginInline: 4,
  },
  Table: {
    cellPaddingBlock: 16,
  },
};

export const COLORS = {
  Primary: "#138140",
  LightGray: "#D7D7D7",
  Purple: "#7B53D1",
  Blue: "#345a7e",
  LightBlue: "#c1d9ec",
  LightPurple: "#F0E9FF",
  Black: "#000000",
  FrostBlue: "#C1D9EC",
  Red: "#ff4d4f",
  Green: "#13A83D",
  LightRed: " #ffc9bb",
  LightGreen: " #cefad0",
  Gray60: "#333333",
  Gray80: "#D9D9D9",
  White: "#ffffff",
  LightWhite: "F1F1F1",
  Silver: "#717171",
  SpanishGray: "#969696",
  OffWhite: "#F3F3F3",
  Gray: "#595959",
  backgroundColor: "rgb(248, 248, 248)",
};
