import styled from "styled-components";
import { COLORS } from "../../constants/global";

export const LoaderContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${COLORS.OffWhite};
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  .animated-button {
    animation: pulse 2s infinite;
  }
`;
