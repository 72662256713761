import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./protectedRoutes";
import { RouteList } from "./routesList";
import { PageNotFound } from "../components";

const RoutesContainer = ({ authRoutes }: any): JSX.Element => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        {authRoutes?.map(
          (
            route: {
              path: string;
              component: React.LazyExoticComponent<() => JSX.Element>;
              pathname: string;
              exact: boolean;
            },
            i: number
          ) => (
            <Route path={route.path} element={<route.component />} key={i} />
          )
        )}

        <Route
          path="/"
          element={
            <Navigate
              to={
                authRoutes?.length > 0
                  ? authRoutes[0]?.path
                  : RouteList[0]?.path
              }
              replace
            />
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default RoutesContainer;
