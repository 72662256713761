import type { MenuProps } from "antd";
import { Menu } from "antd";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { checkChildrenRoute, checkRoutes } from "../../../utils/checkRoute";
import { SIDEBAR_ROUTES } from "../../../constants/common";
import { useSelector } from "react-redux";
import { selectAuthRoutesList } from "../../../configuration/routeSlice";
import Sider from "antd/es/layout/Sider";
import { COLORS } from "../../../constants/global";
import { Logo } from "../header/header.styled";
import fullLogo from "/full-logo.png";
import halfLogo from "/small-logo.png";
import { IRoutes } from "../../../types/common";

type MenuItem = Required<MenuProps>["items"][number];

interface SidebarType {
  collapsed: boolean;
  selectedKey: string;
}

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
};
// Function to generate menu items
const generateMenuItems = (
  items: IRoutes[],
  userAuthList: string[]
): MenuItem[] => {
  return items.map((item) => {
    if (item.children && item.children.length > 0) {
      const childrenFiltered = checkRoutes(item.children, userAuthList);
      const subMenuItems = generateMenuItems(childrenFiltered, userAuthList);
      return {
        key: item.pathname,
        icon: item.icon,
        label: item.name,
        children: subMenuItems,
      };
    } else {
      return {
        key: item.pathname,
        icon: item.icon,
        label: (
          <Link to={item.route as string} style={{ textDecoration: "none" }}>
            {item.name}
          </Link>
        ),
      };
    }
  });
};

const Items = (userAuthList: string[]) => {
  const sidebarItems = checkRoutes(SIDEBAR_ROUTES, userAuthList);
  return generateMenuItems(sidebarItems, userAuthList);
};

const Sidebar = ({ collapsed, selectedKey }: SidebarType) => {
  const userAuthList = useSelector(selectAuthRoutesList);

  const memoizedMenuItems = useMemo(() => Items(userAuthList), [userAuthList]);
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={250}
      style={{
        background: COLORS.White,
        minWidth: 80,
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <Logo
        style={{
          background: "white",
          padding: "10px 10px",
          height: "64px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Link to="/">
          {collapsed ? (
            <img src={halfLogo} alt="Small Earth Logo" width={70} />
          ) : (
            <img src={fullLogo} alt="Small Earth Logo" width={240} />
          )}
        </Link>
      </Logo>
      <Menu
        selectedKeys={[selectedKey]}
        mode="inline"
        inlineCollapsed={collapsed}
        items={memoizedMenuItems}
      />
    </Sider>
  );
};

export default React.memo(Sidebar);
