import { useEffect, useState } from "react";
import { ThemeConfig } from "antd";
import { THEME_CONSTANTS } from "../../constants/global";
import { LeftCircleFilled } from "@ant-design/icons";

export const useThemeGlobal = () => {
  const [themeUnits, setThemeUnits] = useState(THEME_CONSTANTS);

  const updateTheme = () => {
    if (window.innerWidth < 1024) {
      setThemeUnits({
        ...themeUnits,
        fontSize: 14,
        sizeUnit: 3,
        sizeStep: 3,
        menu: {
          collapsedIconSize: 14,
          collapsedWidth: 44,
          dropdownWidth: 50,
          iconSize: 14,
          itemHeight: 28,
          itemPaddingInline: 11,
          itemMarginInline: 2,
        },
        Table: {
          cellPaddingBlock: 8,
        },
      });
    }
  };

  useEffect(() => updateTheme(), []);

  const theme: ThemeConfig = {
    token: {
      // fontFamily:'Roboto Condensed',
      fontFamily: "Hanken Grotesk Variable",
      colorPrimary: "rgb(31, 31, 31)",
      // colorSuccess: "rgb(0, 132, 61)",
      colorError: "#ee1d25",
      // colorWarning:"rgb(252, 227, 0)"
    },
    components: {
      Radio: {
        colorPrimary: "rgb(0, 132, 61)",
        radioSize: 16,
        dotSize: 8,
        wireframe: true,
      },
      Button: {
        borderRadius: 5,
        paddingInline: 20,
        controlHeight: 38,
        lineHeight: 1.8,
        fontSize: 13,

        colorPrimary: "rgb(0, 132, 61)",
        primaryColor: "#fff",
        colorPrimaryHover: "rgb(0, 132, 61)",
        colorPrimaryActive: "rgb(0, 132, 61)",
        primaryShadow:
          "0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 8px 10px -6px rgba(0, 0, 0, 0.10)",

        defaultBg: "rgb(22, 22, 22)",
        defaultBorderColor: "rgb(125, 130, 135)",
        defaultColor: "rgb(255, 255, 255)",
        defaultActiveBg: "rgba(0,0,0,.8)",
        defaultActiveColor: "#ffffff",
        defaultHoverColor: "#ffffff",
        defaultHoverBg: "rgb(51, 51, 51)",
        defaultHoverBorderColor: "rgb(51, 51, 51)",

        colorErrorBg: "red",
        colorLink: "#ee1d25",
        colorLinkActive: "#ee1d25",
        // colorLinkHover: "rgba(236, 33, 39, 0.81)"
      },
      Input: {
        borderRadius: 5,
        controlHeight: 40,
        fontSize: 14,
        hoverBorderColor: "rgb(19,129,64)",
        activeBorderColor: "rgb(19,129,64)",
        activeShadow: "0 0 0 2px rgba(5, 145, 255, 0.1)",
      },
      Tag: {
        borderRadiusSM: 2,
        fontSize: 16,
        colorWarning: "rgb(232, 111, 37)",
        colorWarningBorder: "#FFBB96",
        colorWarningBg: "rgb(254, 241, 225)",

        colorInfo: "rgb(44, 92, 197)",
        colorInfoBorder: "rgb(44, 92, 197)",
        colorInfoBg: "rgb(229, 242, 253)",
      },
      Table: {
        borderRadius: 0,
        borderRadiusLG: 0,
        colorBgContainer: "#ffffff",
        fontWeightStrong: 500,
        paddingContentVertical: 14,
        colorBorderSecondary: "#DFE0E2",
        colorText: "rgba(0, 0, 0, 0.65)",
        cellPaddingBlock: 10,
        cellFontSize: 14,
        headerBg: "rgb(0, 132, 61)",
        headerColor: "#FFFFFF",
        headerSortHoverBg: "#138140",
        headerSortActiveBg: "#138140",

        headerSplitColor: "rgb(0, 132, 61)",
        // "rowHoverBg": "rgb(82, 196, 26)",

        rowSelectedBg: "#effdf3",
        rowSelectedHoverBg: "#d1fae0",
      },
      Menu: {
        borderRadius: 0,
        radiusItem: 0,
        // dropdownWidth: 160,
        itemBorderRadius: 2,
        radiusSubMenuItem: 0,
        subMenuItemBorderRadius: 0,
        itemColor: "rgba(0, 0, 0, 0.85)",
        itemSelectedColor: "#138140",
        margin: 10,
        fontSize: 14,
        itemHeight: 45,
        itemActiveBg: "rgb(209,250,224)",
        colorText: "#138140",
        // colorBgContainer: "#ffffff",
      },
      Cascader: {
        controlItemBgActive: "#e6f4ff",
        borderRadiusSM: 2,
        fontSizeIcon: 12,
      },
      Checkbox: {
        colorPrimaryTextHover: "#1890FF",
      },
      Collapse: {
        headerBg: "rgba(255, 255, 255, 0.02)",
        colorText: "rgb(97, 97, 97)",
        colorTextHeading: "rgb(51, 51, 51)",
        colorBorder: "rgba(0,0,0,0)",
      },
      Slider: {
        trackBg: "rgb(236, 33, 39)",
        railBg: "rgb(255, 255, 255)",
        railHoverBg: "rgb(255, 255, 255)",
        colorBgElevated: "rgb(25,135,84)",
        handleColor: "rgb(255, 255, 255)",
        handleActiveColor: "rgb(255, 255, 255)",
        trackHoverBg: "rgb(236, 33, 39)",
        dotActiveBorderColor: "rgb(255, 255, 255)",
        colorPrimaryBorderHover: "rgb(255, 255, 255)",
      },

      InputNumber: {
        borderRadius: 5,
        borderRadiusLG: 5,
        borderRadiusSM: 5,
        // hoverBg: "rgba(255, 255, 255, 0)",
        handleHoverColor: "rgba(22, 119, 255, 0)",
        activeBorderColor: "rgb(217, 217, 217)",
        hoverBorderColor: "rgb(217, 217, 217)",
        colorPrimary: "rgb(217, 217, 217)",
        addonBg: "rgb(255, 255, 255)",
      },
      Select: {
        borderRadius: 5,
        colorPrimary: "rgb(51, 51, 51)",
        colorPrimaryHover: "rgba(51, 51, 51, 0.7)",
        controlOutline: "rgba(51, 51, 51, 0.1)",
        controlOutlineWidth: 0,
        singleItemHeightLG: 42,
        borderRadiusLG: 0,
        borderRadiusSM: 0,
        borderRadiusXS: 0,
        colorTextPlaceholder: "rgba(5, 5, 5, 0.3)",
        optionSelectedColor: "rgb(255, 255, 255)",
        colorText: "rgb(16,16,16)",
        optionSelectedBg: "rgb(25, 135, 84)",
        controlHeight: 38,
      },
      Switch: {
        colorPrimary: "rgb(0,132,61)",
        colorPrimaryHover: "rgb(0,100,47)",
      },
    },
  };

  return { theme };
};
