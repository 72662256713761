import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/authService";
import PageLoading from "../components/loader/Loading";

const Callback = (): any => {
  const navigate = useNavigate();
  const authService = new AuthService();

  useEffect(() => {
    if (!authService.isAuthenticated()) {
      authService.signInRedirectCallback();
    } else {
      navigate("/");
    }
  }, []);
  return <PageLoading />;
};

export default Callback;
