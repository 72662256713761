import { Button, Result } from "antd";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <Result
      status="warning"
      title="You don't have permission for this module."
      extra={
        <Link to="/login" key="goBack">
          <Button type="primary">Go Back</Button>
        </Link>
      }
    />
  );
};

export default PageNotFound;
