import { Image } from "antd";
import { LoaderContainer } from "./loader.styled";
import SplashScreen from "../../assets/small-earth-loader.png";

const PageLoading = () => {
  return (
    <LoaderContainer>
      <Image
        src={SplashScreen}
        width={224}
        height={224}
        className="animated-button"
        preview={false}
      />
    </LoaderContainer>
  );
};

export default PageLoading;
