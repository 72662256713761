import { Navigate, Outlet } from "react-router-dom";
import AuthService from "../services/authService";
import PageLoading from "../components/loader/Loading";

const ProtectedRoute = () => {
  const authService = new AuthService();
  const isAuthenticated = authService.isAuthenticated();

  if (!isAuthenticated) {
    return (
      <>
        <PageLoading />
        <Navigate to="/login" />
      </>
    );
  }
  return <>{isAuthenticated && <Outlet />}</>;
};

export default ProtectedRoute;
