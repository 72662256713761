import { type UseQueryResult, useQuery } from "react-query";
import {
  ProfileService,
  Volo_Abp_Account_ProfileDto,
} from "../../services/small-earth-services";

const useGetUserProfile = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  isAuthenticated: boolean
): UseQueryResult<Volo_Abp_Account_ProfileDto | undefined, unknown> => {
  return useQuery(
    ["getUserProfile"],
    async () => {
      return await handleRequest(ProfileService.getApiAccountMyProfile());
    },
    {
      enabled: !!isAuthenticated,
    }
  );
};

export default useGetUserProfile;
