export const PERMISSION_LIST = [
  {
    id: "Users",
    label: "User Management",
  },
  {
    id: "Roles",
    label: "Role Management",
  },
  {
    id: "BulletinCategory",
    label: "Bulletin Category",
  },
  {
    id: "Bulletin",
    label: "Bulletin",
  },
  {
    id: "Customer",
    label: "Customer",
  },
  {
    id: "Feedback",
    label: "Feedback",
  },
];

export const ROLE_PERMISSION_LABEL = [
  {
    id: "User",
    label: "User Management",
  },
  {
    id: "Role",
    label: "Role Management",
  },
  {
    id: "BulletinCategory",
    label: "Bulletin Category",
  },
  {
    id: "Bulletin",
    label: "Bulletin",
  },
];
