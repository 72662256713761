import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { IDENTITY_CONFIG } from "../utils/authConst";
import { type Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_CurrentUserDto } from "../services/small-earth-services";

interface AuthState {
  isAuthenticated: boolean | null;
  token: string | null;
  user: Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_CurrentUserDto | null;
}

const sliceState: AuthState = {
  user: {},
  isAuthenticated: null,
  token: null,
};

const slice = createSlice({
  name: "auth",
  initialState: sliceState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { isAuthenticated, token, user },
      }: PayloadAction<{
        isAuthenticated: boolean;
        token: string;
        user: object;
      }>
    ) => {
      state.isAuthenticated = isAuthenticated;
      state.token = token;
      state.user = user;
    },
  },
});

export const { setCredentials } = slice.actions;

export default slice.reducer;

export const selectIsAuthenticated = (state: RootState): boolean | null =>
  state.auth.isAuthenticated;

export const selectToken = (): string => {
  const oidcClientData =
    IDENTITY_CONFIG.authority != null && IDENTITY_CONFIG.client_id != null
      ? localStorage.getItem(
          `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
        )
      : "";
  const jsonData = oidcClientData != null && JSON.parse(oidcClientData);
  return jsonData?.access_token;
};

export const selectUser = (
  state: RootState
): Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_CurrentUserDto | null =>
  state?.auth?.user;
