import { ConfigProvider, Modal } from "antd";
import "./app.css";
import { useThemeGlobal } from "./hooks/custom/useGlobalTheme";
import GlobalStyles from "./globalStyles";
import GlobalLayout from "./layout/globalLayout";
import { useEffect } from "react";
import useSmallEarthApi from "./hooks/useSmallEarthApi";
import AuthService from "./services/authService";
import { selectToken, setCredentials } from "./configuration/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { setRouteList } from "./configuration/routeSlice";
import { getFilteredPermissionData } from "./utils/permission";
import { AbpApplicationConfigurationService } from "./services/small-earth-services";
import {
  REDIRECT_PERMISSION_MESSAGE,
  RELOAD_PERMISSION_MESSAGE,
} from "./constants/common";
import { useNavigate } from "react-router-dom";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useThemeGlobal();
  const authService = new AuthService();
  const token = useSelector(selectToken);
  const { handleRequest } = useSmallEarthApi();

  useEffect(() => {
    authService
      .getUser()
      .then((user) => {
        if (user != null) {
          dispatch(
            setCredentials({
              isAuthenticated: authService.isAuthenticated(),
              token: authService.getToken(),
              user,
            })
          );
        }
      })
      .catch((err) => {
        if (err == null) void authService.signOutRedirectCallback();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token?.length > 0) {
      handleRequest(
        AbpApplicationConfigurationService.getApiAbpApplicationConfiguration()
      )
        .then((response) => {
          const nonFieldLevelPermissionsGroups = getFilteredPermissionData(
            response?.auth?.grantedPolicies
          );

          const RouteList = Object.keys(nonFieldLevelPermissionsGroups).map(
            (item) => item
          );

          dispatch(
            setRouteList({
              currentUserAuthList: response ?? {},
              currentUserRoutesList: RouteList ?? [],
            })
          );
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  window.addEventListener("PERMISSION_CHANGED", (evt: any) => {
    openModal(evt?.detail);
  });

  const openModal = (permissionStatus: string) => {
    Modal.info({
      title: "Alert",
      centered: true,
      content: (
        <div>
          <p>
            {permissionStatus === "RELOAD"
              ? RELOAD_PERMISSION_MESSAGE
              : REDIRECT_PERMISSION_MESSAGE}
          </p>
        </div>
      ),
      onOk() {
        if (permissionStatus === "SIGN_IN") {
          Modal.destroyAll();
          authService.logout();
        } else if (permissionStatus === "REFRESH") {
          Modal.destroyAll();
          authService.signInSilent();
        } else {
          Modal.destroyAll();
          navigate("/login");
        }
      },
    });
  };

  return (
    <>
      <ConfigProvider theme={theme}>
        <GlobalStyles />
        <GlobalLayout />
      </ConfigProvider>
    </>
  );
}

export default App;
