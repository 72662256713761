import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/authService";
import PageLoading from "../components/loader/Loading";

function Login(): JSX.Element {
  const authService = new AuthService();
  const navigate = useNavigate();

  useEffect(() => {
    if (authService.isAuthenticated()) {
      navigate("/");
    } else {
      authService.signInRedirect();
    }
  }, []);

  return <PageLoading />;
}

export default Login;
