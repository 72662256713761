import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import Callback from "../auth/callback";
import Login from "../auth/login";
import { selectIsAuthenticated } from "../configuration/authSlice";
import { selectAuthRoutesList } from "../configuration/routeSlice";
import RoutesContainer from "../routes/RoutesContainer";
import { RouteList } from "../routes/routesList";
import AuthService from "../services/authService";
import { checkRoutes } from "../utils/checkRoute";
import MasterLayout from "./masterLayout";

const GlobalLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const authService = new AuthService();
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState("");
  const isAuthenticated =
    useSelector(selectIsAuthenticated) || authService.isAuthenticated();
  const userAuthList = useSelector(selectAuthRoutesList);
  const authRoutes = checkRoutes(RouteList, userAuthList);

  useEffect(() => {
    const handleResize = () => {
      // Update the collapsed state based on the window width
      setCollapsed(window.innerWidth < 1024);
    };
    // Attach the event listener
    window.addEventListener("resize", handleResize);
    setCollapsed(window.innerWidth < 1024);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) navigate("/login");
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/callback" element={<Callback />} />
      {isAuthenticated && userAuthList?.auth?.grantedPolicies && (
        <Route
          element={
            <MasterLayout
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              isAuthenticated={isAuthenticated}
              selectedKey={selectedKey}
              setSelectedKey={setSelectedKey}
            />
          }
        >
          <Route
            path="/*"
            element={<RoutesContainer authRoutes={authRoutes} />}
          />
        </Route>
      )}
    </Routes>
  );
};
export default GlobalLayout;
