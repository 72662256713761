// import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { selectToken } from "../configuration/authSlice";
import { useSelector } from "react-redux";
import { ApiError, OpenAPI } from "../services/small-earth-services";

export function useSmallEarthApi(): {
  dismissError: () => void;
  error: ApiError | undefined;
  isLoading: boolean;
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>;
} {
  const token = useSelector(selectToken);
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  OpenAPI.BASE = window.__RUNTIME_CONFIG__?.VITE_BASE_URL ?? "";
  OpenAPI.TOKEN = token?.toString();

  const handleRequest = useCallback(async function <T>(request: Promise<T>) {
    setIsLoading(true);
    try {
      const response = await request;
      setError(undefined);
      return response;
    } catch (exception: any) {
      setError(exception);

      throw new Error(exception);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const dismissError = (): void => {
    setError(undefined);
  };

  return { dismissError, error, isLoading, handleRequest };
}

export default useSmallEarthApi;
