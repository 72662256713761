import {
  AccountBookOutlined,
  BookOutlined,
  CustomerServiceOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { IRoutes } from "../types/common";

export const SIDEBAR_ROUTES: IRoutes[] = [
  {
    route: "/role-management",
    name: "Role Management",
    pathname: "Roles",
    icon: <UserAddOutlined />,
    permission: "string",
  },
  {
    route: "/user-management",
    name: "User Management",
    pathname: "Users",
    icon: <UserOutlined />,
  },
  {
    route: "/customer",
    name: "SMS Management",
    pathname: "Customer",
    icon: <UserAddOutlined />,
  },
  {
    route: "/feedback",
    name: "Feedback",
    pathname: "Feedback",
    icon: <CustomerServiceOutlined />,
  },
  {
    name: "Bulletin Management",
    pathname: ["BulletinCategory", "Bulletin"],
    icon: <BookOutlined />,
    children: [
      {
        route: "/bulletin-category",
        name: "Bulletin Category",
        pathname: "BulletinCategory",
        permission: "Default",
        icon: <AccountBookOutlined />,
      },
      {
        route: "/bulletin",
        name: "Bulletin",
        pathname: "Bulletin",
        permission: "Default",
        icon: <BookOutlined />,
      },
    ],
  },
];

export const ROLES_PERMISSION_LIST = ["View", "Create", "Edit", "Delete"];
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATETIME_FORMAT = "YYYY-MM-DD | HH:mm:ss";
export const ACTION_CONSTANTS_VIEW = "VIEW";
export const ACTION_CONSTANTS_EDIT = "EDIT";
export const ACTION_CONSTANTS_DEACTIVATE = "DEACTIVATE";

export const RELOAD_PERMISSION_MESSAGE =
  "Your permission has changed. The current page will be refreshed to load your new permissions.";
export const REDIRECT_PERMISSION_MESSAGE = "Your session has expired.";

export const STATUS_OPTIONS = [
  {
    label: "Active",
    value: "true",
  },
  { label: "Inactive", value: "false" },
];
