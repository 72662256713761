import { lazy } from "react";

export const RouteList = [
  {
    path: "/user-management",
    component: lazy(async () => await import("../pages/user-management")),
    pathname: "Users",
  },
  {
    path: "/role-management",
    component: lazy(async () => await import("../pages/role-management")),
    pathname: "Roles",
  },
  {
    path: "/customer",
    component: lazy(async () => await import("../pages/customer")),
    pathname: "Customer",
  },
  {
    path: "/bulletin-category",
    component: lazy(
      async () => await import("../pages/bulletin-board-management/category")
    ),
    pathname: "BulletinCategory",
  },
  {
    path: "/bulletin",
    component: lazy(
      async () =>
        await import("../pages/bulletin-board-management/bulletin-board")
    ),
    pathname: "Bulletin",
  },
  {
    path: "/feedback",
    component: lazy(async () => await import("../pages/feedback")),
    pathname: "Feedback",
  },
  {
    path: "/page-not-found",
    component: lazy(async () => await import("../components/page-not-found")),
    pathname: "pageNotFound",
  },
];
