import styled from "styled-components";

export const ProtectedRouterWrapper = styled.div`
  padding: 20px 18px;
  height: 100%;
  max-width: 100%;
  overflow-y: auto;

  .ant-table-wrapper
    .ant-table-thead
    th.ant-table-column-has-sorters:hover::before {
    background-color: white !important;
  }
  h3 {
    white-space: nowrap;
  }
`;
